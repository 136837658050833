import Swiper from "swiper";
import {
    Navigation,
    Pagination,
    EffectFade,
    Autoplay
} from 'swiper/modules';
Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

/**
 * Initialize swiper instances
 */
export default function sliders() {
    const carousels = [...document.querySelectorAll("[data-swiper-instance]")];

    /**
     * Loop through each carousel instance and initialize Swiper.
     */
    for (const instance of carousels) {
        const element = instance.querySelector("[data-swiper]");
        const prev = instance.querySelector("[data-prev]");
        const next = instance.querySelector("[data-next]");
        const pagination = instance.querySelector("[data-pagination]");
        const additionalPrevBtns = [...instance.querySelectorAll('[data-prev-extra]')]
        const additionalNextBtns = [...instance.querySelectorAll('[data-next-extra]')]

        const jsonString = atob(instance.dataset.swiperInstance);
        const props = JSON.parse(jsonString);

        /**
         * Set navigation options for the main swiper based on the availability of nextEl and prevEl.
         */
        const navigation = {};

        if (next) {
            navigation.nextEl = next;
        }

        if (prev) {
            navigation.prevEl = prev;
        }

        // Set props.navigation only if there are navigation options
        if (Object.keys(navigation).length > 0) {
            props.navigation = navigation;
        }

        /**
         * Set pagination options for the main swiper.
         */
        if (pagination) {
            props.pagination = {
                clickable: true,
                el: pagination,
            };
        }

        /**
         * Initialize the main swiper.
         * @type {Swiper}
         */
        const swiper = new Swiper(element, props);

        additionalPrevBtns.forEach(extraBtn => {
            extraBtn.addEventListener('click', e => {
                e.preventDefault();
                swiper.slidePrev();
            });
        });

        additionalNextBtns.forEach(extraBtn => {
            extraBtn.addEventListener('click', e => {
                e.preventDefault();
                swiper.slideNext();
            });
        });
    }
}
