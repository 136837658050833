import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/offcanvas';
import runOnMedia from '@parent/js/runOnMedia.js';
import swiperSliders from './sliders.js';
import modals from '@parent/js/modals.js';
import kmTabs from './kmTabs.js';
import Breadcrumbs from '@parent/js/breadcrumbs.js';
import traverseMenu from '@parent/js/traverseMenu.js';

window.addEventListener('DOMContentLoaded', e => {

    modals();
    swiperSliders();
    traverseMenu('[data-main-menu]');

    for (const instance of [...document.querySelectorAll('[data-breadcrumbs]')]) {
        const wrapper = instance.querySelector('ul');

        new Breadcrumbs({
            crumbs: wrapper,
            minDepth: 2,
            triggerExtra: `
              <span class="km-icon km-svg-crumbs-open"></span>
              <span class="km-icon km-svg-crumbs-closed"></span>
            `,
        });
    }

    /**
     * Tabbed Content
     */
    const tabbedInstances = [...document.querySelectorAll('.km-content-tabs')];

    tabbedInstances.forEach(instance => {
        let triggers = [...instance.querySelectorAll('[data-km-tab-trigger]')];

        const tabsProps = {
            initial: 1,
            triggers: triggers,
            contents: [...instance.querySelectorAll('[data-km-tab-content]')]
        };

        if (triggers.length) {
            const activeTab = triggers[0];

            tabsProps.initial = activeTab.dataset.kmTab;
        }

        kmTabs(tabsProps);
    });

    /**
     * Handle main menu change between mobile and desktop
     */
    const menuContainer = document.querySelector('[data-main-menu]');
    const desktopMenuClass = 'desktop-menu';
    const mobileMenuClass = 'mobile-menu';

    if (menuContainer) {
        runOnMedia('(max-width: 991px)', {
            onChange: (isMatch, menuContainer, desktopMenuClass, mobileMenuClass) => {
                if (!menuContainer) return;
                menuContainer.classList.remove(mobileMenuClass, desktopMenuClass);
                menuContainer.classList.add(isMatch ? mobileMenuClass : desktopMenuClass);
            },
            args: [menuContainer, desktopMenuClass, mobileMenuClass]
        });
    }

    /**
     * Handle Footer collapsibles based on screen
     */
    const footerCollapsibles = [...document.querySelectorAll('.main-footer .collapse')];

    runOnMedia('(max-width: 991px)', {
        onChange: (isMatch) => {
            for (const collapsible of footerCollapsibles) {
                const id = collapsible.id;
                if (!id) return;
                collapsible.classList.toggle('show', !isMatch);

                const triggers = document.querySelectorAll(`[data-bs-target="#${id}"]`);
                for (const trigger of triggers) {
                    trigger.classList.toggle('collapsed', !isMatch);
                    trigger.setAttribute('aria-expanded', isMatch ? 'false' : 'true');
                }
            }
        },
        args: [footerCollapsibles]
    });
});